<template>
  <div class="flex flex-col h-full">
    <layout-page-head-section :force-blur="albumSelection.hasAny.value">
      <div class="flex gap-2 sm:gap-4 sm:items-start">

        <div class="flex flex-1 gap-4" v-auto-animate="{duration: 100}" :class="isCondensedHeader ? 'items-center' : null">
          <template v-if="!isCondensedHeader">
            <client-only>
              <div class="relative flex" @click="() => isEntityOwner ? contextActions.editThumbnail.click() : null" :role="isEntityOwner ? 'button' : null" :title="isEntityOwner ? contextActions.editThumbnail.label : null">
                <album-avatar :album="album" :size="avatarSize" />
              </div>
            </client-only>

            <div class="flex-1">
              <p class="text-xs text-charcoal-400 uppercase" v-if="!isEntityOwner">Album by <nuxt-link :to="buildRoute.toUserProfile({user: album.user})">{{album.user.name}}</nuxt-link></p>
              <layout-page-heading>{{album.name}}</layout-page-heading>
              <p v-if="album.displayDate" class="text-gray-500/80">{{album.displayDate}}</p>
              <album-privacy-badge v-if="isEntityOwner" :album="album" />
              <client-only>
                <div class="2xl:w-4/5" v-if="album.description">
                  <span class="text-base text-gray-600 whitespace-pre-wrap leading-none">{{descPreview}}</span>
                  <u-button class="ml-2" variant="link" :padded="false" @click="contextActions.viewDetails.click" v-if="album.description.length > descPreviewLength">View More</u-button>
                </div>
              </client-only>

              <u-badge v-if="addedSince"  variant="subtle" color="salmon">{{album.files_added}} New Files!</u-badge>
            </div>
          </template>
          <template v-else>
            <album-avatar :album="album" size="sm" />
            <p class="flex-1 font-serif text-2xl">{{album.name}}</p>
          </template>
        </div>

        <div class="gap-2 flex sm:hidden justify-end items-start">
          <u-dropdown :items="mobileOverflowMenu">
            <u-button
              :icon="COMMON_ICONS.moreHorizontal"
              variant="outline"
              color="charcoal"
            />
          </u-dropdown>


          <album-collaboration-popover v-if="album.can_contribute && !isAuthenticated" />
        </div>

        <div class="gap-2 hidden sm:flex justify-end">

          <u-button-group v-if="isEntityOwner" size="sm">
            <u-tooltip :text="contextActions.viewDetails.label" v-if="album.description">
              <u-button
                @click="contextActions.viewDetails.click"
                :icon="contextActions.viewDetails.icon"
                variant="outline"
                color="charcoal"
              />
            </u-tooltip>

            <u-tooltip :text="contextActions.print.label">
              <u-button
                :icon="contextActions.print.icon"
                variant="outline"
                color="charcoal"
                @click="contextActions.print.click"
              />
            </u-tooltip>
            <u-tooltip :text="contextActions.share.label">
              <u-button
                :icon="contextActions.share.icon"
                variant="outline"
                color="charcoal"
                @click="contextActions.share.click"
              />
            </u-tooltip>

            <u-dropdown :items="desktopOwnerOverflowMenu">
              <u-button
                :icon="COMMON_ICONS.moreHorizontal"
                variant="outline"
                color="charcoal"
              />
            </u-dropdown>
          </u-button-group>

          <u-button-group v-else-if="isAuthenticated" size="sm">
            <u-tooltip :text="contextActions.viewDetails.label" v-if="album.description">
              <u-button
                @click="contextActions.viewDetails.click"
                :icon="contextActions.viewDetails.icon"
                variant="outline"
                color="charcoal"
              />
            </u-tooltip>
            <u-tooltip :text="contextActions.download.label">
              <u-button
                :icon="contextActions.download.icon"
                variant="outline"
                color="charcoal"
                @click="contextActions.download.click"
              />
            </u-tooltip>
            <u-tooltip :text="contextActions.copy.label">
              <u-button
                :icon="contextActions.copy.icon"
                variant="outline"
                color="charcoal"
                @click="contextActions.copy.click"
              />
            </u-tooltip>
            <u-tooltip :text="contextActions.viewSlideshow.label">
              <u-button
                :icon="contextActions.viewSlideshow.icon"
                variant="outline"
                color="charcoal"
                @click="contextActions.viewSlideshow.click"
              />
            </u-tooltip>
          </u-button-group>

          <u-button-group v-else size="sm">
            <u-tooltip :text="contextActions.viewDetails.label" v-if="album.description">
              <u-button
                @click="contextActions.viewDetails.click"
                :icon="contextActions.viewDetails.icon"
                variant="outline"
                color="charcoal"
              />
            </u-tooltip>
            <u-tooltip :text="contextActions.viewSlideshow.label" :prevent="!album.description">
              <u-button
                :icon="contextActions.viewSlideshow.icon"
                variant="outline"
                color="charcoal"
                @click="contextActions.viewSlideshow.click"
              />
            </u-tooltip>
            <u-tooltip :text="contextActions.download.label">
              <u-button
                :icon="contextActions.download.icon"
                variant="outline"
                color="charcoal"
                @click="contextActions.download.click"
              />
            </u-tooltip>
          </u-button-group>

          <u-dropdown v-if="album.can_contribute && isAuthenticated" :items="addFilesDropdownItems">
            <core-responsive-button size="sm" :icon="COMMON_ICONS.add">Add Files</core-responsive-button>
          </u-dropdown>

          <album-collaboration-popover v-else-if="album.can_contribute" />

        </div>

      </div>
    </layout-page-head-section>

    <div class="flex-1 -mr-[9px] lg:-mr-[29px] overflow-hidden isolate">
      <file-list
        :context="album"
        :context-type="FILE_CONTEXTS.album"
        :share-token="shareToken"
        :user-id="userId"
        :disable-selection="!isAuthenticated"
        :added-since="addedSince"
        loading-text="Loading Files"
        @list-item-click="goToFile"
      >

        <template #header>
          <div v-if="subAlbums?.length" class="-mt-2">
            <u-accordion :items="accordionItems">
              <template #default="{open, item}">
                <div class="flex gap-3 items-center justify-between">
                  <button class="flex gap-1.5 items-center py-2 outline-0" @click="item.toggle(!open)">
                    <span class="text-base uppercase text-charcoal-400 font-semibold leading-none">Nested Albums ({{album.albums_count}})</span>
                    <u-icon :name="open ? 'i-ri-arrow-up-s-line' : 'i-ri-arrow-down-s-line'" class="text-charcoal-400 w-5 h-5 -mt-px" />
                  </button>
                  <u-button v-if="showAllAlbumsButton && open" color="charcoal" variant="link" @click.stop="() => (showAllAlbums = !showAllAlbums)">Show {{showAllAlbums ? 'Less' : 'All'}}</u-button>
                </div>
              </template>

              <template #subalbums>
                <core-list :items="displaySubAlbums" container-class="grid grid-cols-3 xs:grid-cols-4 sm:grid-cols-5 md:grid-cols-7 lg:grid-cols-6 xl:grid-cols-7 2xl:grid-cols-10 3xl:grid-cols-12" grid-gap-class="gap-3 2xl:gap-5" :section-size="displaySubAlbums.length">
                  <template #default="{ item: subAlbum }">
                    <album-list-item
                      is-nested
                      class="h-full"
                      :album="subAlbum"
                      :key="subAlbum.id"
                      :selected="albumSelection.has(subAlbum)"
                      :selectable="isEntityOwner"
                      @update:selected="toggleSelection(subAlbum)"
                      :selection-candidate="albumSelection.isCandidate(subAlbum)"
                      @item-click="onItemClick(subAlbum)"
                      @mouseover="albumSelection.updateSelectionCandidates({item: subAlbum, collection: subAlbums})"
                    />
                  </template>
                </core-list>
              </template>
            </u-accordion>

            <u-divider class="pt-1 pb-3" />
          </div>
        </template>

        <template #empty-state>
          <core-empty-state v-if="isEntityOwner"
            heading="Add Files to This Album"
            description="Let's get organized! Add some files to this album and make your collection shine."
            :icon="COMMON_ICONS.album"
          >
            <p class="text-center mt-8">
              <u-dropdown v-if="album.can_contribute" :items="addFilesDropdownItems">
                <u-button size="xl">Start Adding Files</u-button>
              </u-dropdown>
            </p>
          </core-empty-state>
          <core-empty-state v-else
            heading="This Album is Empty"
            description="No files have been added to this album yet."
            :icon="COMMON_ICONS.album"
          />
        </template>
      </file-list>
    </div>

    <file-selection-toolbar :actions="fileSelectionActions" :context="album" :context-type="FILE_CONTEXTS.album" :user-id="userId" v-if="isAuthenticated" />

    <album-selection-toolbar :parent-id="album.id" v-if="isEntityOwner" />

    <nuxt-page :page-key="route => route.name" />
  </div>
</template>

<script setup>
  import {storeToRefs} from 'pinia';
  import {useStorage} from '@vueuse/core';

  const route = useRoute();
  const {album_id_or_slug: albumId, user_id_or_slug: userId, share_token: shareToken} = route.params;
  const albumsStore = useAlbumsStore();
  const {album, nestedAlbums} = storeToRefs(albumsStore);
  const isEntityOwner = computed(() => album.value?.isOwnedByUser);
  const addedSince = computed(() => route.query.since);
  const fileActions = useFileActions();
  const {getContextActions} = useAlbumActions();
  const {isAuthenticated} = useSessionStore();
  const buildRoute = useBuildRoute();
  const scrSize = useScreenSize();
  const albumSelection = useSelection(ALBUM_SELECTION_COMPOSABLE_KEY);

  //avatar size
  const avatarSize = computed(() => {
    if (scrSize.lg.value) return '5xl';
    if (scrSize.sm.value) return '4xl';
    return '3xl';
  });


  //description preview
  const descPreviewLength = computed(() => scrSize.sm.value ? 225 : 100);
  const descPreview = computed(() => {
    return album.value?.description?.substring(0, descPreviewLength.value).concat(album.value.description.length > descPreviewLength.value ? '...' : '')
  });


  //subalbums display
  const subAlbumDisplayCount = computed(() => {
    const sizeMap = {
      '3xl': 12,
      '2xl': 10,
      'xl': 7,
      'lg': 6,
      'md': 7,
      'sm': 5,
      'xs': 4
    };

    for (const [key, value] of Object.entries(sizeMap)) {
      if (scrSize[key].value) {
        return value;
      }
    }

    return 3;
  });

  const subAlbums = computed(() => {
    //check if array because when loading, the result of the get can be an object
    const result = album.value && nestedAlbums.value.get(album.value.id);
    return Array.isArray(result) ? result : [];
  });

  const showAllAlbums = useStorage('core-subalbums-expanded', false);

  const displaySubAlbums = computed(() => {

    if (showAllAlbums.value) {
      return subAlbums.value;
    }

    if (subAlbums.value?.slice) {
      return subAlbums.value.slice(0, subAlbumDisplayCount.value);
    }

    return [];
  });
  const showAllAlbumsButton = computed(() => subAlbums.value?.length > subAlbumDisplayCount.value);
  const areAlbumsOpen = useStorage('core-subalbums-open', true);
  const accordionItems = [
    {
      label: 'Additional Details',
      slot: 'subalbums',
      defaultOpen: areAlbumsOpen.value,
      toggle: val => {
        areAlbumsOpen.value = val;
      }
    }
  ];


  //get album details
  const {refresh, error} = await useAsyncData(
    `albumDetails-${albumId}`,
    () => albumsStore.getAlbum({id: albumId, userId, shareToken, addedSince: addedSince.value}),
    {
      watch: [addedSince]
    }
  );

  if (error.value) {
    throw createError({
      ...error.value
    });
  }

  useForeverHead({
    title: computed(() => `${album.value.name} | Album by ${album.value.user.name}`),
    description: album.value.description,
    socialImage: album.value.avatarImage
  });

  function goToFile({item: file}) {
    navigateTo(`${route.path.replace(/\/$/, '')}/files/${file.id}${buildQueryString(route.query)}`);
  }

  function toggleSelection(album) {
    albumSelection.toggle({item: album});
  }


  //item
  function onItemClick(album) {
    if (albumSelection.hasAny.value) {
      toggleSelection(album);
    } else {
      navigateTo(useBuildRoute().toAlbum({
        album,
        userId,
        shareToken
      }));
    }
  }


  //file selection actions
  const allFileSelectionActions = fileActions.selectionToolbarActions;
  const fileSelectionActions = computed(() => {
    if (isEntityOwner.value) {
      return [
        allFileSelectionActions.organize,
        allFileSelectionActions.moveToAlbum,
        allFileSelectionActions.share,
        allFileSelectionActions.download,
        allFileSelectionActions.makeCover,
        allFileSelectionActions.removeFromContainer
      ];
    }

    if (isAuthenticated) {
      return [
        allFileSelectionActions.download,
        allFileSelectionActions.copyToMyGallery
      ];
    }

  });


  //context actions
  const contextActions = getContextActions({
    album: album.value,
    userId,
    shareToken
  });

  const addFilesDropdownItems = [[
    contextActions.addFiles,
    contextActions.upload
  ]];

  const desktopOwnerOverflowMenu = [
    [
      contextActions.edit,
      contextActions.editThumbnail,
      contextActions.move,
      contextActions.addNested,
      contextActions.viewSlideshow,
      contextActions.download
    ],
    [
      contextActions.delete
    ]
  ];

  const mobileOverflowMenu = computed(() => {
    if (isEntityOwner.value) {
      return [
        [
          contextActions.addFiles,
          contextActions.upload,
        ],
        [
          contextActions.viewDetails,
          contextActions.edit,
          contextActions.editThumbnail,
          contextActions.move,
          contextActions.addNested,
          contextActions.share,
          contextActions.print,
          contextActions.viewSlideshow,
          contextActions.download
        ],
        [
          contextActions.delete
        ]
      ];
    }

    if (isAuthenticated) {
      return [
        album.value.can_contribute ? [
          contextActions.addFiles,
          contextActions.upload,
        ] : null,
        [
          contextActions.viewDetails,
          contextActions.download,
          contextActions.copy,
          contextActions.viewSlideshow
        ]
      ].filter(n => n);
    }

    return [[
      contextActions.viewDetails,
      contextActions.viewSlideshow,
      contextActions.download
    ]];
  });


  //condensed header
  const isCondensedHeader = ref(false);
  useEventBus(FILE_LIST_SCROLL_THRESHOLD_EVENT).on(status => {
    isCondensedHeader.value = !!status;
  });


  //albums panel & nested albums
  const {open: openAlbums, close: closeAlbums} = useAlbumsNav();

  onMounted(async () => {
    if (album.value) {
      openAlbums();
    } else {
      watchOnce(album, openAlbums);
    }

    if (album.value?.albums_count) {
      albumsStore.getNestedAlbums({
        parentAlbumId: album.value.id,
        userId,
        shareToken
      });
    }

  });


  //set up the signup cookie which allows us to link users to the sharer during signup.
  onMounted(async () => {
    if (!isAuthenticated && shareToken) {
      useExternalRedirect().setSignupCookie({
        value: userId,
        context: album.value.can_contribute ? 'collaborate' : 'share'
      });
    }
  });

  onUnmounted(() => {
    albumSelection.clear();
  });

  onBeforeRouteLeave(() => {
    closeAlbums();
    albumsStore.clearNestedAlbums();
  });
</script>
